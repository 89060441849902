import { createModel } from "@/util/ModelUtil";

export default class OrganizationModel {
  constructor(
    public id: number = 1,
    public name: string = "",
    public companyName: string = "",
    public businessNumber: string = "",
    public code: string = "",
    public email: string = "",
    public maxUser: number = 0,
    public subDomain: string = "",
    public mainBannerImageUrl: string = "",
    public mainLogoImageUrl: string = "",
    public contractTerm: number = 0,
    public createdAt: number = new Date().getTime(),
    public updatedAt: number = new Date().getTime(),
    public specialTrackMenuName: string = "",
    public isAutoLearningStart: boolean = false
  ) {}

  public static create(organization?: Organization): OrganizationModel {
    return createModel<OrganizationModel>(OrganizationModel, organization);
  }
}
